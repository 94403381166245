import {shortcuts, shortcutPassesCondition, IShortcut} from './index';
import h from 'stringjsx';

export function init(): void {
  window.addEventListener(`keydown`, event => {
    const SHIFT = 16;
    const ALT = 18;
    if (event.shiftKey && event.altKey && (event.keyCode == SHIFT || event.keyCode == ALT)) {
      showIndicators();
    }
  });

  window.addEventListener(`keyup`, event => {
    if (event.shiftKey || event.altKey) {
      hideIndicators();
    }
  });

  window.addEventListener(`blur`, event => {
    hideIndicators();
  });
}

function addIndicator(element: Element, shortcut: IShortcut) {
  const indicator = document.createElement(`div`);
  indicator.setAttribute(`js-shortcut-button-indicator`, `true`);
  indicator.innerHTML = (
    <div class="shortcut-button-indicator">
      {shortcut.triggerKey}
    </div>
  );

  element.appendChild(indicator);
}

function showIndicators(): void {
  shortcuts
    .forEach(shortcut => {
      if (!shortcutPassesCondition(shortcut)) return;

      if (typeof shortcut.elementSelector == `string`) {
        document
          .querySelectorAll(shortcut.elementSelector)
          .forEach(element => {
            addIndicator(element, shortcut);
          });
      }

      if (typeof shortcut.elementSelector == `function`) {
        const element = shortcut.elementSelector();
        if (element) { addIndicator(element, shortcut); }
      }
    });
}

function hideIndicators(): void {
  document.querySelectorAll(`[js-shortcut-button-indicator]`)
    .forEach(element => element.parentNode!.removeChild(element));
}
