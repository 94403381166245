// startLoadingSpinner adds `cursor-progress` to parent element so the cursor is
// progress. We set it on the parent element since it doesn't take effect when
// the button is disabled since we set `pointer-events: none;` when the button
// is disables. We set `pointer-events: none;` since we don't want to have hover
// effects when it's disablesd.
export function startLoadingSpinner(button: HTMLButtonElement) {
  button.disabled = true;
  // keep a direct reference to the parentElement since the button might not be
  // a part of the dom when the remove event happen
  const parentElement = button.parentElement;
  if (button.parentElement) {
    button.parentElement.classList.add(`cursor-progress`);
  }

  const spinner = document.createElement(`i`);
  spinner.classList.add(`fa`);
  spinner.classList.add(`fa-spinner`);
  spinner.classList.add(`fa-pulse`);
  spinner.classList.add(`m-r-1`);

  button.insertAdjacentElement(`afterbegin`, spinner);

  return () => {
    spinner.remove();
    button.disabled = false;
    if (parentElement) {
      parentElement.classList.remove(`cursor-progress`);
    }
  };
}

// A svelte action version of startLoadingSpinner().
// Usage: <button use:loadingSpinner={shouldShowLoadingSpinner}>
type LoadingSpinnerOptions = {show: boolean};
export function loadingSpinner(button: HTMLButtonElement, show: boolean) {
  let stopLoadingSpinner: Function | undefined;

  function updateLoadingSpinner(show: boolean) {
    if (show) {
      if (stopLoadingSpinner) { stopLoadingSpinner(); }

      stopLoadingSpinner = startLoadingSpinner(button);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (stopLoadingSpinner) {
        stopLoadingSpinner();
        stopLoadingSpinner = undefined;
      }
    }
  }

  return {
    update: (show: boolean) => {
      updateLoadingSpinner(show);
    },
    // we have to update spinner again when we destroy the button since parent
    // is modified
    destroy() {
      updateLoadingSpinner(false);
    }
  };
}

import {shakeToDrawAttention} from '@/modules/element_animator';

type TimeoutID = ReturnType<typeof setTimeout>;
let revealYouHaveValidationErrorsTimeoutId: TimeoutID | null = null;

export function revealYouHaveValidationErrors(msgElement: Element) {
  if (revealYouHaveValidationErrorsTimeoutId) {
    clearTimeout(revealYouHaveValidationErrorsTimeoutId);
  }

  msgElement.classList.remove(`hidden`);

  // This first timeout is here to make the ux feel just a little bit better.
  // Experimentation revealed that it didn't feel quite right unless the text
  // appears a few milliseconds before it starts shaking.
  setTimeout(() => {
    shakeToDrawAttention({element: msgElement as HTMLElement});

    revealYouHaveValidationErrorsTimeoutId = setTimeout(() => {
      msgElement.classList.add(`hidden`);
    }, 2500);
  }, 79);
}
