import {shortcuts, settings} from './index';

export function init(): void {
  new MutationObserver(addStylesToElements)
    .observe(document.body, {
      childList: true, // observe direct children
      subtree: true, // and lower descendants too
    });

  window.addEventListener(`Shortcuts.shortcutRegistered`, addStylesToElements);
}

function addStylesToElement(element: Element) {
  element.classList.add(`has-shortcut`);
  if (settings.underlineShortcuts) {
    element.classList.add(`always-has-shortcut`);
  }
}

function addStylesToElements() {
  shortcuts
    .forEach(shortcut => {
      if (!shortcut.elementSelector) return;

      if (typeof shortcut.elementSelector == `string`) {
        document
          .querySelectorAll(shortcut.elementSelector)
          .forEach(addStylesToElement);
      }

      if (typeof shortcut.elementSelector == `function`) {
        const element = shortcut.elementSelector();
        if (element) { addStylesToElement(element); }
      }
    });
}
